import Vue from 'vue';

Vue.directive('input', {
  inserted(el, binding, vnode) {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', binding.value);
    el.parentElement.appendChild(input);

    input.value = JSON.stringify(vnode.componentInstance.value);
    vnode.componentInstance.$on('input', (value) => {
      input.value = JSON.stringify(value);
    });
  },
});
